<template>

    <div class="content-wrapper">
        <bo-page-title>
        </bo-page-title>

        <div class="content pt-0">
            <div class="card">
            
                <b-card-header>
                <b-row>
                    <b-col lg="8">
                    <b-form-row>
                        <b-col lg="4">
                        <b-form-group class="mb-0">
                            <v-select placeholder="Pilih Poli" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </b-form-group>
                        </b-col>
                        
                        <div class="col-md-1 d-flex">
                            <div class="form-group mb-0">
                                <button @click="addjadwalDokter()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                                    class="btn btn-light" title="Tambah Jadwal Dokter" v-b-tooltip.hover><i class="icon-plus2"></i></button>
                            </div>
                            
                            <div class="form-group mb-0 ml-2">
                                <button @click="modalSearch=true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                                    class="btn btn-light" title="Cek Jadwal Dokter" v-b-tooltip.hover><i class="icon-search4"></i></button>
                            </div>
                        </div>
                    </b-form-row>
                    </b-col>

                    <b-col lg="4">
                    <SearchInput :value.sync="filter.search" @search="doFill" />
                    </b-col>
                </b-row>

                </b-card-header>

                <div class="table-responsive">
                    <table class="table table-bordered table-striped  table-sm patient-table text-uppercase table-sm">
                    <!--
                    :class="v.isHead ? 'bt-separated' : ''"
                    -->
                    <template v-for="(v,k) in (dataList||[])">
                        <thead v-if="v.head" :key="k">
                            <tr class="table-info">
                                <th colspan="8">{{v.mpo_name||"-"}}
                                </th>
                                <th>
									<button @click="doGetJadwal(v)" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover><i class="icon-spinner11"></i></button>
                                </th>
                                </tr>
                                <tr>
                                <th>#</th>
                                <th>Nama Dokter</th>
                                <th :class="getDays == 'Senin' ? 'table-info':''">Senin</th>
                                <th :class="getDays == 'Selasa' ? 'table-info':''">Selasa</th>
                                <th :class="getDays == 'Rabu' ? 'table-info':''">Rabu</th>
                                <th :class="getDays == 'Kamis' ? 'table-info':''">Kamis</th>
                                <th :class="getDays == 'Jumat' ? 'table-info':''">Jumat</th>
                                <th :class="getDays == 'Sabtu' ? 'table-info':''">Sabtu</th>
                                <th>Aksi</th>

                            </tr>
                        </thead>
                        <template v-else>
                            <tr :key="k" :class="v.isHead ? 'bt-separated': ''">
                                <!--
                                :rowspan="v.rowspan" :class="v.isHead ? 'bt-separated': ''"
                                -->
                                <td :rowspan="v.rowspan" v-if="v.isHead">{{v.noHead}}</td>
                                <td :rowspan="v.rowspan" v-if="v.isHead">{{v.bu_full_name||"-"}}</td>
                                <td :class="getDays == 'Senin' ? 'table-info':''">
                                    <span v-if="v.arjd_mon_start && v.arjd_mon_end">{{v.arjd_mon_start}} - {{v.arjd_mon_end}}<br/>
                                    <!--
                                    <b style="font-size: 9px;">Kuota Dokter : {{v.arjd_mon_kuota_default||"-"}}</b>
                                    -->
                                    <div class="list-icons mt-1">
                                        <a href="javascript:;" style="padding:4px;" v-b-tooltip.hover class="list-icons-item" data-toggle="tooltip" data-placement="top" title="Atur Jadwal" @click="settingJadwal(v,'Monday')"><i class="icon-cog"></i></a>
                                    </div>
                                    </span>

                                    <span v-else> - </span> <br/>

                                </td>
                                <td :class="getDays == 'Selasa' ? 'table-info':''">
                                    <span v-if="v.arjd_tue_start && v.arjd_tue_end">{{v.arjd_tue_start}} - {{v.arjd_tue_end}}<br/>
                                    <!--
                                    <b style="font-size: 9px;">Kuota Dokter : {{v.arjd_tue_kuota_default||"-"}}</b>
                                    -->
                                    <div class="list-icons mt-1">
                                        <a href="javascript:;" style="padding:4px;" v-b-tooltip.hover class="list-icons-item" data-toggle="tooltip" data-placement="top" title="Atur Jadwal" @click="settingJadwal(v,'Tuesday')"><i class="icon-cog"></i></a>
                                    </div>
                                    </span>

                                    <span v-else> - </span>
                                </td>
                                <td :class="getDays == 'Rabu' ? 'table-info':''">
                                    <span v-if="v.arjd_wed_start && v.arjd_wed_end">{{v.arjd_wed_start}} - {{v.arjd_wed_end}}<br/>
                                    <!--
                                    <b style="font-size: 9px;">Kuota Dokter : {{v.arjd_wed_kuota_default||"-"}}</b>
                                    -->
                                    <div class="list-icons mt-1">
                                        <a href="javascript:;" style="padding:4px;" v-b-tooltip.hover class="list-icons-item" data-toggle="tooltip" data-placement="top" title="Atur Jadwal" @click="settingJadwal(v,'Wednesday')"><i class="icon-cog"></i></a>
                                    </div>
                                    </span>

                                    <span v-else> - </span>
                                </td>
                                <td :class="getDays == 'Kamis' ? 'table-info':''">
                                    <span v-if="v.arjd_thu_start && v.arjd_thu_end">{{v.arjd_thu_start}} - {{v.arjd_thu_end}}<br/>
                                    <!--
                                    <b style="font-size: 9px;">Kuota Dokter : {{v.arjd_thu_kuota_default||"-"}}</b>
                                    -->
                                    <div class="list-icons mt-1">
                                        <a href="javascript:;" style="padding:4px;" v-b-tooltip.hover class="list-icons-item" data-toggle="tooltip" data-placement="top" title="Atur Jadwal" @click="settingJadwal(v,'Thursday')"><i class="icon-cog"></i></a>
                                    </div>
                                    </span>

                                    <span v-else> - </span>
                                </td>
                                <td :class="getDays == 'Jumat' ? 'table-info':''">
                                    <span v-if="v.arjd_fri_start && v.arjd_fri_end">{{v.arjd_fri_start}} - {{v.arjd_fri_end}}<br/>
                                    <!--
                                    <b style="font-size: 9px;">Kuota Dokter : {{v.arjd_fri_kuota_default||"-"}}</b>
                                    -->
                                    <div class="list-icons mt-1">
                                        <a href="javascript:;" style="padding:4px;" v-b-tooltip.hover class="list-icons-item" data-toggle="tooltip" data-placement="top" title="Atur Jadwal" @click="settingJadwal(v,'Friday')"><i class="icon-cog"></i></a>
                                    </div>
                                    </span>

                                    <span v-else> - </span>
                                </td>
                                <td :class="getDays == 'Sabtu' ? 'table-info':''">
                                    <span v-if="v.arjd_sat_start && v.arjd_sat_end">{{v.arjd_sat_start}} - {{v.arjd_sat_end}}<br/>
                                    <!--
                                    <b style="font-size: 9px;">Kuota Dokter : {{v.arjd_sat_kuota_default||"-"}}</b>
                                    -->
                                    <div class="list-icons mt-1">
                                        <a href="javascript:;" style="padding:4px;" v-b-tooltip.hover class="list-icons-item" data-toggle="tooltip" data-placement="top" title="Atur Jadwal" @click="settingJadwal(v,'Saturday')"><i class="icon-cog"></i></a>
                                    </div>
                                    </span>

                                    <span v-else> - </span>
                                </td>
                                <td>
									<button @click="getJadwal(v)" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Edit Jadwal" v-b-tooltip.hover><i class="icon-cog"></i></button>
                                </td>
                            </tr>
                        </template>
                    </template>
                    
                    <tbody v-if="dataList.length == 0">
                        <tr>
                            <td colspan="99">
                            <div class="text-center">
                                <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                    
                    <tbody v-if="!dataList">   
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>

        <validation-observer
            ref="VFormJadwalDokter"
        >
        <b-modal v-model="isOpenSetting" scrollable :title="'Atur Kuota Pemeriksaan'" @ok.prevent="submitDokter" size="md" ok-title="Simpan">
            <h6>Jadwal Dokter Tanggal : {{rowSetting.ark_date | moment("DD MMMM YYYY")}}</h6>
            <div class="row mb-2">
                <div class="col-md-6">
                    <label for="">Kuota Dokter Pada Aplikasi Hfis : 
                    <br/>
                    <strong style="font-size: 27px;">{{rowSetting.ark_kuota||0}}</strong></label>
                    <b-button @click="tutupDokter()"
                        class="btn-sm d-inline-flex align-items-center"
                        variant="outline-danger"
                    >Tutup Kuota Dokter</b-button>
                </div>
                <div class="col-md-6">
                   <b-form-group class="mb-2">
                        <label for="">Kuota Dokter Saat Ini</label>
                        <div class="input-group">
                            <b-form-input type="text" :disabled="!rowSetting.isEdit" v-model="rowSetting.ark_kuota_daily" class="form-control" placeholder="Jumlah Kuota" :formatter="numberOnly" />
                            <div @click="isEditRow()" v-b-tooltip.hover.right title="Ubah Jadwal" class="input-group-append c-pointer">
                                <span class="input-group-text"><i class="icon-cog"></i></span>
                            </div>
                        </div>
                        
                        <VValidate 
                            name="Kuota Dokter Saat Ini" 
                            v-model="rowSetting.ark_kuota_daily" 
                            :rules="{required:1}"
                        />

                        <span class="text-info" style="font-size: 10px;"> *) Jika Anda ingin membuka kembali pendaftaran, harap isi kuota dokter</span>
                    </b-form-group>
                </div>
                <div class="col-md-8">
                    <label for="">Keterangan Perubahan </label>
                    <b-form-textarea v-model="rowSetting.ket" 
                        name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                        placeholder="Keluhan Utama dari pasien">
                    </b-form-textarea>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12">
                    <h6 class="mt-4">Riwayat Jadwal Dokter</h6>
                    <table class="table table-sm table-bordered table-hover">
                        <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Tanggal / Waktu Periksa</th>
                            <th width="100">Jumlah Hfis</th>
                            <th width="100">Jumlah Diubah</th>
                            <th width="100">Keterangan</th>
                            <th width="100">Diubah Pada</th>
                        </tr>
                        <tr v-for="(v,k) in (rowRiwayat||[])" :key="k">
                            <td>{{k+1}}</td>
                            <td>{{v.lhpj_tgl_jadwal | moment("DD MMMM YYYY")}} / {{v.lhpj_waktu}}</td>
                            <td>{{v.lhpj_jml_awal||"0"}}</td>
                            <td>{{v.lhpj_jml_ubah||"0"}}</td>
                            <td>{{v.lhpj_ket||"-"}}</td>
                            <td>{{v.lhpj_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                        </tr>
                        <tr v-if="!(rowRiwayat||[]).length">
                            <td class="text-center" colspan="5">Tidak Ada Riwayat</td>
                        </tr>

                        </thead>
                    </table>

                </div>
            </div>
        </b-modal>
        </validation-observer>
    
    
        <validation-observer
            ref="VFormAdd"
        >
            <b-modal v-model="modalAdd" scrollable :title="'Tambah Jadwal Dokter'" @ok.prevent="submitAddJadwal" size="lg" ok-title="Simpan">
                <div class="row mb-2">
                    <div class="col-md-6">
                        <b-form-group class="mb-0">
                        <label for="">Pilih Poli<span class="text-danger">*</span></label>
                        <v-select placeholder="Pilih Poli" @input="selectDokter($event);rowEdit.arjd_dokter_id=null" v-model="rowEdit.arjd_mpo_id" :options="mPoliTujuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate :name="`Poli`"
                        v-model="rowEdit.arjd_mpo_id"
                        :rules="{required: 1}" />
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group class="mb-0">
                        <label for="">Pilih Dokter<span class="text-danger">*</span></label>
                        <v-select placeholder="Pilih Dokter" v-model="rowEdit.arjd_dokter_id"  :options="mDokterTujuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </b-form-group>
                        <VValidate :name="`Dokter`"
                        v-model="rowEdit.arjd_dokter_id"
                        :rules="{required: 1}" />
                    </div>
                    
                    <div class="col-md-8 mt-2">
                        <b-form-group class="mb-0">
                        <label for="">Keterangan Perubahan</label>
                        <b-form-textarea v-model="rowEdit.keterangan_ubah" 
                            name="perRiwayat" id="perRiwayat" rows="4" class="form-control"
                            placeholder="Keluhan Utama dari pasien">
                        </b-form-textarea>
                        </b-form-group>
                    </div>

                    <div class="col-md-12 mt-3">
                        <b-form-group class="mb-0">
                            <label for="">Jadwal Dokter</label>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Hari</th>
                                            <th>Senin</th>
                                            <th>Selasa</th>
                                            <th>Rabu</th>
                                            <th>Kamis</th>
                                            <th>Jumat</th>
                                            <th>Sabtu</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Jam Prakter</th>
                                            <td class="text-center">
                                                <vue-timepicker placeholder="Jam Mulai" input-class="form-control" v-model="rowEdit.arjd_mon_start" format="HH:mm">
                                                </vue-timepicker>
                                                <span>s/d</span>
                                                <vue-timepicker placeholder="Jam Selesai" input-class="form-control" v-model="rowEdit.arjd_mon_end" format="HH:mm">
                                                </vue-timepicker>
                                                
                                                <VValidate :name="`Jam Senin`"
                                                v-if="rowEdit.arjd_mon_start"
                                                v-model="rowEdit.arjd_mon_end"
                                                :rules="{required: 1, greater_than_start: rowEdit.arjd_mon_start}" />

                                            </td>
                                            <td>
                                                <vue-timepicker placeholder="Jam Mulai" input-class="form-control" v-model="rowEdit.arjd_tue_start" format="HH:mm">
                                                </vue-timepicker>
                                                <span>s/d</span>
                                                <vue-timepicker placeholder="Jam Selesai" input-class="form-control" v-model="rowEdit.arjd_tue_end" format="HH:mm">
                                                </vue-timepicker>
                                                
                                                <VValidate :name="`Jam Selasa`"
                                                v-if="rowEdit.arjd_tue_start"
                                                v-model="rowEdit.arjd_tue_end"
                                                :rules="{required: 1, greater_than_start: rowEdit.arjd_tue_start}" />
                                            </td>
                                            
                                            <td>
                                                <vue-timepicker placeholder="Jam Mulai" input-class="form-control" v-model="rowEdit.arjd_wed_start" format="HH:mm">
                                                </vue-timepicker>
                                                <span>s/d</span>
                                                <vue-timepicker placeholder="Jam Selesai" input-class="form-control" v-model="rowEdit.arjd_wed_end" format="HH:mm">
                                                </vue-timepicker>
                                                
                                                <VValidate :name="`Jam rabu`"
                                                v-if="rowEdit.arjd_wed_start"
                                                v-model="rowEdit.arjd_wed_end"
                                                :rules="{required: 1, greater_than_start: rowEdit.arjd_wed_start}" />
                                            </td>
                                            
                                            <td>
                                                <vue-timepicker placeholder="Jam Mulai" input-class="form-control" v-model="rowEdit.arjd_thu_start" format="HH:mm">
                                                </vue-timepicker>
                                                <span>s/d</span>
                                                <vue-timepicker placeholder="Jam Selesai" input-class="form-control" v-model="rowEdit.arjd_thu_end" format="HH:mm">
                                                </vue-timepicker>
                                                
                                                <VValidate :name="`Jam Kamis`"
                                                v-if="rowEdit.arjd_thu_start"
                                                v-model="rowEdit.arjd_thu_end"
                                                :rules="{required: 1, greater_than_start: rowEdit.arjd_thu_start}" />
                                            </td>
                                            
                                            <td>
                                                <vue-timepicker placeholder="Jam Mulai" input-class="form-control" v-model="rowEdit.arjd_fri_start" format="HH:mm">
                                                </vue-timepicker>
                                                <span>s/d</span>
                                                <vue-timepicker placeholder="Jam Selesai" input-class="form-control" v-model="rowEdit.arjd_fri_end" format="HH:mm">
                                                </vue-timepicker>
                                                
                                                <VValidate :name="`Jam Jumat`"
                                                v-if="rowEdit.arjd_fri_start"
                                                v-model="rowEdit.arjd_fri_end"
                                                :rules="{required: 1, greater_than_start: rowEdit.arjd_fri_start}" />
                                            </td>
                                            
                                            <td>
                                                <vue-timepicker placeholder="Jam Mulai" input-class="form-control" v-model="rowEdit.arjd_sat_start" format="HH:mm">
                                                </vue-timepicker>
                                                <span>s/d</span>
                                                <vue-timepicker placeholder="Jam Selesai" input-class="form-control" v-model="rowEdit.arjd_sat_end" format="HH:mm">
                                                </vue-timepicker>
                                                
                                                <VValidate :name="`Jam Sabtu`"
                                                v-if="rowEdit.arjd_sat_start"
                                                v-model="rowEdit.arjd_sat_end"
                                                :rules="{required: 1, greater_than_start: rowEdit.arjd_sat_start}" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <th>Kuota Dokter</th>
                                            <td>
                                                <b-form-input v-model="rowEdit.arjd_mon_kuota_default" :formatter="numberOnly" type="text" name="name" class="form-control" />
                                                
                                                <VValidate :name="`Kuota Senin`"
                                                v-if="rowEdit.arjd_mon_start"
                                                v-model="rowEdit.arjd_mon_kuota_default"
                                                :rules="{required: 1}" />
                                            </td>
                                            <td>
                                                <b-form-input v-model="rowEdit.arjd_tue_kuota_default" :formatter="numberOnly" type="text" name="name" class="form-control" />
                                                
                                                <VValidate :name="`Kuota Selasa`"
                                                v-if="rowEdit.arjd_tue_start"
                                                v-model="rowEdit.arjd_tue_kuota_default"
                                                :rules="{required: 1}" />
                                            </td>
                                            <td>
                                                <b-form-input v-model="rowEdit.arjd_wed_kuota_default" :formatter="numberOnly" type="text" name="name" class="form-control" />
                                                
                                                <VValidate :name="`Kuota Rabu`"
                                                v-if="rowEdit.arjd_wed_start"
                                                v-model="rowEdit.arjd_wed_kuota_default"
                                                :rules="{required: 1}" />
                                            </td>
                                            <td>
                                                <b-form-input v-model="rowEdit.arjd_thu_kuota_default" :formatter="numberOnly" type="text" name="name" class="form-control" />
                                                
                                                <VValidate :name="`Kuota Kamis`"
                                                v-if="rowEdit.arjd_thu_start"
                                                v-model="rowEdit.arjd_thu_kuota_default"
                                                :rules="{required: 1}" />
                                            </td>
                                            <td>
                                                <b-form-input v-model="rowEdit.arjd_fri_kuota_default" :formatter="numberOnly" type="text" name="name" class="form-control" />
                                                
                                                <VValidate :name="`Kuota Jumat`"
                                                v-if="rowEdit.arjd_fri_start"
                                                v-model="rowEdit.arjd_fri_kuota_default"
                                                :rules="{required: 1}" />
                                            </td>
                                            <td>
                                                <b-form-input v-model="rowEdit.arjd_sat_kuota_default" :formatter="numberOnly" type="text" name="name" class="form-control" />
                                                
                                                <VValidate :name="`Kuota Sabtu`"
                                                v-if="rowEdit.arjd_sat_start"
                                                v-model="rowEdit.arjd_sat_kuota_default"
                                                :rules="{required: 1}" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>     

                            <label for="">Riwayat Perubahan Dokter</label>
                            <div class="table-responsive">
                                <table class="table table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">No</th>
                                            <th rowspan="2">Tanggal Perubahan</th>
                                            <th colspan="3" class="text-center">Senin</th>
                                            <th colspan="3" class="text-center">Selasa</th>
                                            <th colspan="3" class="text-center">Rabu</th>
                                            <th colspan="3" class="text-center">Kamis</th>
                                            <th colspan="3" class="text-center">Jumat</th>
                                            <th colspan="3" class="text-center">Sabtu</th>
                                            <th rowspan="2">Keterangan</th>
                                            <th rowspan="2">Diubah Oleh</th>
                                        </tr>
                                        <tr>
                                            <th>Mulai</th>
                                            <th>Selesai</th>
                                            <th>Kuota</th>
                                            
                                            <th>Mulai</th>
                                            <th>Selesai</th>
                                            <th>Kuota</th>
                                            
                                            <th>Mulai</th>
                                            <th>Selesai</th>
                                            <th>Kuota</th>
                                            
                                            <th>Mulai</th>
                                            <th>Selesai</th>
                                            <th>Kuota</th>
                                            
                                            <th>Mulai</th>
                                            <th>Selesai</th>
                                            <th>Kuota</th>
                                            
                                            <th>Mulai</th>
                                            <th>Selesai</th>
                                            <th>Kuota</th>
                                        </tr>
                                        <tr v-for="(v,k) in (logJadwal||[])" :key="k">
                                            <td>{{k+1}}</td>

                                            <td>{{v.lpjd_log_date | moment("DD MMMM YYYY, HH:mm")}} WIB</td>
                                            <td>{{v.lpjd_mon_start||"-"}}</td>
                                            <td>{{v.lpjd_mon_end||"-"}}</td>
                                            <td>{{v.lpjd_mon_kuota||"-"}}</td>
                                            
                                            <td>{{v.lpjd_tue_start||"-"}}</td>
                                            <td>{{v.lpjd_tue_end||"-"}}</td>
                                            <td>{{v.lpjd_tue_kuota||"-"}}</td>

                                            <td>{{v.lpjd_wed_start||"-"}}</td>
                                            <td>{{v.lpjd_wed_end||"-"}}</td>
                                            <td>{{v.lpjd_wed_kuota||"-"}}</td>

                                            <td>{{v.lpjd_thu_start||"-"}}</td>
                                            <td>{{v.lpjd_thu_end||"-"}}</td>
                                            <td>{{v.lpjd_thu_kuota||"-"}}</td>

                                            <td>{{v.lpjd_fri_start||"-"}}</td>
                                            <td>{{v.lpjd_fri_end||"-"}}</td>
                                            <td>{{v.lpjd_fri_kuota||"-"}}</td>
                                            
                                            <td>{{v.lpjd_sat_start||"-"}}</td>
                                            <td>{{v.lpjd_sat_end||"-"}}</td>
                                            <td>{{v.lpjd_sat_kuota||"-"}}</td>

                                            <td>{{v.lpjd_keterangan||"-"}}</td>
                                            <td>{{v.lpjd_user_by||"-"}}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                        </b-form-group>
                    </div>
                </div>
            </b-modal>
        </validation-observer>

        <validation-observer
            ref="VFormSearch"
        >
            <b-modal v-model="modalSearch" scrollable :title="'Cek Jadwal Dokter'" @ok.prevent="modalSearch = false" ok-only size="lg" ok-title="Tutup">
                <div class="row mb-2">
                    <div class="col-md-5">
                        <b-form-group class="mb-0">
                        <label for="">Pilih Dokter<span class="text-danger">*</span></label>
                        <v-select placeholder="Pilih Dokter" v-model="filterDokter.dokter"  :options="mDokterTujuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </b-form-group>
                        <VValidate :name="`Dokter`"
                        v-model="filterDokter.dokter"
                        :rules="{required: 1}" />
                    </div>

                    <div class="col-md-5">
                        <b-form-group class="mb-0">
                            <label for="stepNotes">Tanggal Pemeriksaan <span
                                class="text-danger mr5">*</span></label>
                            <div class="input-group">
                                <div class="input-group mb-3">
                                    <datepicker 
                                    input-class="form-control transparent"
                                    placeholder="Tanggal Pemeriksaan" class="my-datepicker"
                                    calendar-class="my-datepicker_calendar"
                                    v-model="filterDokter.tanggal">
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i
                                        class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>

                            <VValidate :name="`Tanggal Pemeriksaan`" 
                            v-model="filterDokter.tanggal"
                            :rules="{required: 1}" />
                        </b-form-group>
                    </div>

                    <div class="col-md-2">
                        <button @click="seacrhJadwal" style="
                            margin-top: 27px !important;
                        " type="button" class="btn bg-blue-400 text-orange-800 mt-3"><b><i
                            class="icon-search4"></i></b></button>
                    </div>
                </div>     
                <!-- v-if="Object.keys(resultDokter||{}).length" -->
                <div v-if="resultDokter.isShow" class="row mb-2">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <tbody>
                                <tr>
                                    <th>Nama Dokter</th>
                                    <th>{{resultDokter.namaDokter||"-"}}</th>
                                </tr>
                                <tr>
                                    <th>Tanggal Praktek</th>
                                    <th>{{resultDokter.tanggal | moment("DD MMMM YYYY")}}</th>
                                </tr>
                                <tr>
                                    <th>Sisa Kuota</th>
                                    <th>
                                        <div class="table-responsive">
                                            <table class="table table-bordered mb-0">
                                                <tr v-for="(v,k) in (resultDokter.dataKuota||[])" :key="k">
                                                    <td>{{v.jadwalStart}}-{{v.jadwalEnd}}</td>
                                                    <td>{{v.kuota||0}}</td>
                                                </tr>
                                                <tr v-if="!(resultDokter.dataKuota||[]).length">
                                                    <td colspan="2">Tidak ada kuota</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  
            </b-modal>                    
        </validation-observer>

    </div>
</template>


<script>
    import GlobalVue from '@/libs/Global.vue'
    const moment = require('moment')
    import Gen from '@/libs/Gen.js'
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import Datepicker from 'vuejs-datepicker'
    
    export default {
        extends: GlobalVue,
        mounted(){
            this.apiGet()
        },
        components:{ VueTimepicker,Datepicker },
        data() {
            return {
                mPoli : [],
                rowSetting : {},
                isOpenSetting : false,
                rowRiwayat: [],
                modalAdd: false,
                modalSearch: false,
                mPoliTujuan: [],
                mDokterTujuan: [],
                poli: null,
                dokter: null,
                rowEdit: {
                    arjd_id : null,
                    arjd_dokter_id : null,
                    arjd_mpo_id : null,
                    arjd_mon_start : "",
                    arjd_mon_end : "",
                    arjd_tue_start : "",
                    arjd_tue_end : "",
                    arjd_wed_start : "",
                    arjd_wed_end : "",
                    arjd_thu_start : "",
                    arjd_thu_end : "",
                    arjd_fri_start : "",
                    arjd_fri_end : "",
                    arjd_sat_start : "",
                    arjd_sat_end : "",
                    arjd_mon_kuota_default: null,
                    arjd_tue_kuota_default: null,
                    arjd_wed_kuota_default: null,
                    arjd_thu_kuota_default: null,
                    arjd_fri_kuota_default: null,
                    arjd_sar_kuota_default: null,
                },
                filterDokter : {
                    dokter : null,
                    tanggal : null
                },
                resultDokter : {
                    dataKuota : [],
                    namaDokter : null,
                    tanggal : null,
                    isShow: false
                },
                logJadwal: []
            }
        },
        computed: {
            getDays(){
                return moment().format('dddd')
            },
        },
        methods: {   
            addjadwalDokter(){
                this.rowEdit = {
                    arjd_id : null,
                    arjd_dokter_id : null,
                    arjd_mpo_id : null,
                    arjd_mon_start : "",
                    arjd_mon_end : "",
                    arjd_tue_start : "",
                    arjd_tue_end : "",
                    arjd_wed_start : "",
                    arjd_wed_end : "",
                    arjd_thu_start : "",
                    arjd_thu_end : "",
                    arjd_fri_start : "",
                    arjd_fri_end : "",
                    arjd_sat_start : "",
                    arjd_sat_end : "",
                    arjd_sun_start: "",
                    arjd_sun_end: "",
                    arjd_mon_kuota_default: null,
                    arjd_tue_kuota_default: null,
                    arjd_wed_kuota_default: null,
                    arjd_thu_kuota_default: null,
                    arjd_fri_kuota_default: null,
                    arjd_sar_kuota_default: null,
                }   
                this.modalAdd = true
            },

            getJadwal(v){
                this.rowEdit = v
                this.rowEdit.arjd_mon_start = this.rowEdit.arjd_mon_start ? this.rowEdit.arjd_mon_start : "" 
                this.rowEdit.arjd_mon_end = this.rowEdit.arjd_mon_end ? this.rowEdit.arjd_mon_end : ""

                this.rowEdit.arjd_tue_start = this.rowEdit.arjd_tue_start ? this.rowEdit.arjd_tue_start : "" 
                this.rowEdit.arjd_tue_end = this.rowEdit.arjd_tue_end ? this.rowEdit.arjd_tue_end : ""
                
                this.rowEdit.arjd_wed_start = this.rowEdit.arjd_wed_start ? this.rowEdit.arjd_wed_start : "" 
                this.rowEdit.arjd_wed_end = this.rowEdit.arjd_wed_end ? this.rowEdit.arjd_wed_end : ""
                
                this.rowEdit.arjd_thu_start = this.rowEdit.arjd_thu_start ? this.rowEdit.arjd_thu_start : "" 
                this.rowEdit.arjd_thu_end = this.rowEdit.arjd_thu_end ? this.rowEdit.arjd_thu_end : ""
                
                this.rowEdit.arjd_fri_start = this.rowEdit.arjd_fri_start ? this.rowEdit.arjd_fri_start : "" 
                this.rowEdit.arjd_fri_end = this.rowEdit.arjd_fri_end ? this.rowEdit.arjd_fri_end : ""
                
                this.rowEdit.arjd_sat_start = this.rowEdit.arjd_sat_start ? this.rowEdit.arjd_sat_start : "" 
                this.rowEdit.arjd_sat_end = this.rowEdit.arjd_sat_end ? this.rowEdit.arjd_sat_end : ""
                
                this.rowEdit.arjd_sun_start = this.rowEdit.arjd_sun_start ? this.rowEdit.arjd_sun_start : "" 
                this.rowEdit.arjd_sun_end = this.rowEdit.arjd_sun_end ? this.rowEdit.arjd_sun_end : ""
                
                this.modalAdd = true

                this.loadingOverlay = true
                let data = {
                    type : "get-log",
                    id : v.arjd_id
                } 
                Gen.apiRest("/do/"+this.modulePage,{data: data}, "POST")
                .then(res=>{
                    this.logJadwal = res.data
                    this.loadingOverlay = false
                })
            },
            selectDokter(e){
                let data = {
                    type : "get-dokter",
                    mpo_id : e 
                } 
                Gen.apiRest("/do/"+this.modulePage,{data: data}, "POST")
                .then(res=>{
                    this.mDokterTujuan = res.data
                })
            },
            submitAddJadwal(){
                this.$refs['VFormAdd'].validate().then(success=>{
                    if(success){
                        this.loadingOverlay = true
                        let data = this.rowEdit

                        data.type = "submit-jadwal-dokter"
                        Gen.apiRest(
                        "/do/" + this.modulePage, {
                            data: data
                        },
                        "POST"
                        ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if (result.value) {
                                    this.rowEdit = {
                                        arjd_id : null,
                                        arjd_dokter_id : null,
                                        arjd_mpo_id : null,
                                        arjd_mon_start : "",
                                        arjd_mon_end : "",
                                        arjd_tue_start : "",
                                        arjd_tue_end : "",
                                        arjd_wed_start : "",
                                        arjd_wed_end : "",
                                        arjd_thu_start : "",
                                        arjd_thu_end : "",
                                        arjd_fri_start : "",
                                        arjd_fri_end : "",
                                        arjd_sat_start : "",
                                        arjd_sat_end : "",
                                        arjd_sun_start: "",
                                        arjd_sun_end: "",
                                        arjd_mon_kuota_default: null,
                                        arjd_tue_kuota_default: null,
                                        arjd_wed_kuota_default: null,
                                        arjd_thu_kuota_default: null,
                                        arjd_fri_kuota_default: null,
                                        arjd_sar_kuota_default: null,
                                    }   
                                    this.apiGet()
                                    this.modalAdd = false
                                }
                            })
                        }).catch(err => {
                            this.loadingOverlay = false
                            if (err) {
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })
                }
                })
            },
            seacrhJadwal(){
                this.$refs['VFormSearch'].validate().then(success=>{
                    if(success){
                        this.loadingOverlay = true
                        let data = this.filterDokter
                        data.type = "cari-jadwal-dokter"
                        Gen.apiRest(
                        "/do/" + this.modulePage, {
                            data: data
                        },
                        "POST"
                        ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            console.log(resp)
                            this.resultDokter = resp
                        }).catch(err => {
                            this.loadingOverlay = false
                            if (err) {
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })

                    }
                })
            },
            doGetJadwal(v){
                this.loadingOverlay = true

                let data = {
                    type : "get-jadwal-dokter",
                    mpo_id : v.mpo_id 
                }            

                Gen.apiRest("/do/"+this.modulePage,{data: data}, "POST")
                .then((resp = {})=>{
                    this.loadingOverlay = false       
                    this.apiGet()         
                    return this.$swal({
                        icon: 'success',
                        title: 'Berhasil memuat kembali jadwal dokter'
                    })
                })

            },       
            doFill(){
                this.doFilter()
            },
            settingJadwal(row,nameDay=''){
                this.loadingOverlay = true                
                Gen.apiRest(
                    "/get/"+'RoJadwalDokter'+'/'+row.arjd_id+'?dayName='+nameDay, 
                ).then(res=>{
                    this.loadingOverlay = false
                    this.rowSetting = res.data.row
                    this.rowRiwayat = res.data.riwayat
                    this.isOpenSetting = true
                })
            },
            isEditRow(){
                this.$set(this.rowSetting, "isEdit", true)
            },
            submitDokter(){
                this.$refs['VFormJadwalDokter'].validate().then(success => {
                    if(success){
                        this.loadingOverlay = true        
                        let data = this.rowSetting
                        data.type = "update-jadwal-dokter"

                        Gen.apiRest("/do/"+this.modulePage,{data: data}, "POST")
                        .then((resp = {})=>{
                            this.loadingOverlay = false
                            this.apiGet()         
                            this.isOpenSetting = false
                            return this.$swal({
                                icon: 'success',
                                title: 'Jadwal Dokter berhasil diubah'
                            })
                        })
                    }
                })
            },
            tutupDokter(){
                this.loadingOverlay = true       
                let data = this.rowSetting
                data.type = "tutup-dokter"

                Gen.apiRest("/do/"+this.modulePage,{data: data}, "POST")
                .then((resp = {})=>{
                    this.loadingOverlay = false
                    this.apiGet()           
                    this.isOpenSetting = false
                    return this.$swal({
                        icon: 'success',
                        title: 'Jadwal Dokter berhasil ditutup'
                    })
                })
            }
        },
        watch:{
            $route(){
                this.apiGet()
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>